import "core-js/stable";
import "regenerator-runtime/runtime";

import "./js/config.js";

// Load in vendor scripts (jquery loaded by Webpack directly);
import "bootstrap";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';

// Load in styles
import "./scss/styles.scss";

// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.


// Load in custom JS modules required on initial page load

import('./js/viewport-size');

if (document.querySelector('body.site-layout') != null) {
    import('./js/scrolling');
    import('./js/keyboard-accessibility-check');
}

if (document.querySelector('#full-menu') != null) {
    import('./js/main-navigation');
}

if (document.querySelector('#cookie-consent') != null) {
    import('./js/cookie-bar');
}

if (document.querySelector('.emergency-message') != null) {
    import('./js/emergency-message');
}

// Load in custom JS modules on initial page load and when DOM is updated

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`,'');
    });
}

const jsModuleCheck = () => {

    let testQuery = null;

    // height matching
    testQuery = document.querySelector('*[data-hm-group]:not([data-js-parsed--height-match])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-hm-group]:not([data-js-parsed--height-match])');
        addDataJsParsed(thisDomQuery, "height-match");
        import('./js/height-match').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // non-lazyload img-load-watcher
    testQuery = document.querySelector('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
        addDataJsParsed(thisDomQuery, "img-load-watcher");
        import('./js/img-load-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // list-carousel
    testQuery = document.querySelector('.list-carousel:not([data-js-parsed--list-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.list-carousel:not([data-js-parsed--list-carousel])');
        addDataJsParsed(thisDomQuery, "list-carousel");
        import('./js/list-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // lightbox-image
    testQuery = document.querySelector('.lightbox-image:not([data-js-parsed--lightbox-image])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.lightbox-image:not([data-js-parsed--lightbox-image])');
        addDataJsParsed(thisDomQuery, "lightbox-image");
        import('./js/lightbox-image').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // image gallery
    testQuery = document.querySelector('.image-gallery:not([data-js-parsed--image-gallery])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.image-gallery:not([data-js-parsed--image-gallery])');
        addDataJsParsed(thisDomQuery, "image-gallery");
        import('./js/image-galleries').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - vimeo
    testQuery = document.querySelector('.hero--video.vimeo:not([data-js-parsed--vimeo])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.vimeo:not([data-js-parsed--vimeo])');
        addDataJsParsed(thisDomQuery, "vimeo");
        import('./js/hero-video-vimeo').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - youtube
    testQuery = document.querySelector('.hero--video.youtube:not([data-js-parsed--youtube])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.youtube:not([data-js-parsed--youtube])');
        addDataJsParsed(thisDomQuery, "youtube");
        import('./js/hero-video-youtube').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }
    
    // opening times
    testQuery = document.querySelector('.opening-times:not([data-js-parsed--calendar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.opening-times:not([data-js-parsed--calendar])');
        addDataJsParsed(thisDomQuery, "calendar");
        import('./js/calendar');
        import('./js/opening-times');
    }
    
    // responsive nav bar
    testQuery = document.querySelector('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
        addDataJsParsed(thisDomQuery, "responsive-nav-bar");
        import('./js/responsive-nav-bar').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

};

//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('main');
if(targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function(mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for(const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                checkNeeded = true;
            }
        }
        if(checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 500);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}